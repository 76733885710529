import * as actionTypes from "../actions/userActions";

const initialState = {
  userList: null,
  traineeList: null,
  userDetail: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_LIST:
      return {
        ...state,
        [action.payload.as]: action.payload.userList
      };
    case actionTypes.SET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload.userDetail
      };
    default:
      return state;
  }
};

export default userReducer;
