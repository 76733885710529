import validate from "./validator";
import {showSnackbar} from "../store/actions/coreActions";

export const extractFormData = (form = {}, onlyDirty = true) => {
  return (form.controls || []).reduce((prev, control) => {
    if(onlyDirty && control.dirty) {
      prev[control.name] = control.value;
    }
    if(!onlyDirty) {
      prev[control.name] = control.value;
    }
    return prev;
  }, {});
};

export const getMultiformSubmitClickHandler =
  (dispatch, formConfig, successHandlers, formIndex, instanceId, type = 'data') =>
    async (uploads = null) => {
      const {allForms, setAllForms} = formConfig;
      const {save, edit} = successHandlers;
      const form = allForms[formIndex];
      const formValidity = validate.form(form);
      form.controls.forEach((c, i) => {
        validate.multiformValidateInput(allForms, formIndex, setAllForms)(i, c.value);
      });
      if(!formValidity.isValid) {
        return;
      }
      const formData = extractFormData(form);
      const dataKeys = Object.keys(formData);
      if(!((dataKeys.length) || (uploads && (uploads.files[0] || {}).file))) {
        dispatch(showSnackbar({ message: `No changes made to ${type}!` }));
        return;
      }
      if(instanceId) {
        await edit(instanceId, formData, uploads);
        return;
      }
      await save(formData, uploads);
    };
