export const Validators = {
  REQUIRED: 'REQUIRED',
  PATTERN: 'PATTERN',
  MIN_LENGTH: 'MIN_LENGTH',
  MAX_LENGTH: 'MAX_LENGTH',
  EQUALS: 'EQUALS'
};

export const updateErrorClassName = (className = "", validity= {isValid: true}) => {
  const errorClass = "sme-invalid";
  let classNames = className.split(" ");
  if(classNames.includes(errorClass) && validity.isValid) {
    classNames = classNames.filter(c => c.trim() !== errorClass);
  }else
  if (!classNames.includes(errorClass) && !validity.isValid) {
    classNames = classNames.concat(errorClass);
  }
  return classNames.join(" ");
};

const form = (fm) => {
  return fm.controls.reduce((prev, ctr) => {
    const ctrVal = control(fm, ctr.name, ctr.value);
    // ctrVal.errors = ctrVal.errors.concat(...(ctr.validity.errors.filter(e => !e.isEqualError)));
    return {
      isValid: !ctrVal.errors.length && prev.isValid,
      errors: prev.errors.concat(...ctrVal.errors)
    };
  }, {isValid: true, errors: []});
};

const equalValidator = (ctr1, ctr2) => {
  const isValid = ctr1.value === ctr2.value;
  return !isValid ? {
    isEqualError: true,
    ctrName: ctr1.name,
    message: `${(ctr1.parentProps || {}).errorLabel || ctr1.name} does not match with 
    ${(ctr2.parentProps || {}).errorLabel || ctr2.name}`
  } : null;
};

const equalValidatorAll = (f,  controlName, updatedValue) => {
  let equalRules = ((f.validators || [])
    .filter(v => v.name === Validators.EQUALS) || []).map(a => Object.values(a.value));
  let result = [];
  equalRules.forEach(equalRule => {
    if(equalRule.find(e => e === controlName)) {
      equalRule = equalRule.map(e => {
        e = {...(f.controls.find(c => c.name === e))};
        e = {
          ...e,
          value: controlName === e.name ? updatedValue : e.value
        };
        return e;
      });
      const err = equalValidator(...equalRule);
      result = err ? result.concat(err) : result;
    }
  });
  return result;
};

const control = (fm = {}, ctrName, ctrVal) => {
  const ctr = (fm.controls || []).find(c => c.name === ctrName) || fm;
  let isValid = true;
  let errors = [];
  if(ctr && ctr.validators) {
    ctr.validators.forEach(v => {
      if (!isValid) {
        return;
      }
      try {
        switch (v.name) {
          case Validators.REQUIRED:
            isValid = isValid && !!ctrVal.toString().trim();
            errors = !isValid ? errors.concat(
              {ctr: ctr.name, message: `${(ctr.parentProps || {}).errorLabel 
                || ctr.name} is required`}) : errors;
            break;
          case Validators.MIN_LENGTH:
            isValid = isValid && ctrVal.toString().length >= v.value;
            errors = !isValid ? errors.concat(
              {ctr: ctr.name,
                message: `${(ctr.parentProps || {}).errorLabel
                || ctr.name} must not be less than ${v.value} characters`}
            ) : errors;
            break;
          case Validators.MAX_LENGTH:
            isValid = isValid && ctrVal.toString().length <= v.value;
            errors = !isValid ? errors.concat(
              {ctr: ctr.name,
                message: `${(ctr.parentProps || {}).errorLabel 
                || ctr.name} must not be more than ${v.value} characters`}
            ) : errors;
            break;
          case Validators.PATTERN:
            isValid = isValid && v.value.test(ctrVal);
            errors = !isValid ? errors.concat(
              {ctr: ctr.name, message: `Invalid ${(ctr.parentProps || {}).errorLabel || ctr.name}`}
            ) : errors;
            break;
          default:
            break;
        }
      } catch (e) {
        errors.push(e);
      }
    });
  }
  return {isValid, errors};
};

const multiformValidateInput = (allForms, formIndex, updateFormModule) => (ctrInd, ctrVal) => {
  let c = allForms[formIndex].controls[ctrInd];
  if(!c) {
    return {isValid: false, errors: []};
  }
  const validity = control(
    c,
    c.name,
    ctrVal
  );
  c = {
    ...c,
    value: ctrVal,
    dirty: c.dirty || (c.value !== ctrVal),
    validity,
    inputProps: {
      ...c.inputProps,
      className: updateErrorClassName(c.inputProps.className, validity)
    }
  };
  const newModuleForms = [...allForms];
  newModuleForms[formIndex].controls[ctrInd] = c;
  updateFormModule(newModuleForms);
  return validity;
};

const validate = {control, form, equalValidatorAll, multiformValidateInput};


export default validate;
