import Classroom from "../assets/icons/classroom.svg";
import Community from "../assets/icons/community.svg";
import EventManager from "../assets/icons/event-manager.svg";
import Logout from "../assets/icons/logout.svg";
import Manage from "../assets/icons/manage.svg";
import Overview from "../assets/icons/overview.svg";
import Setting from "../assets/icons/setting.svg";
import User from "../assets/icons/user.svg";
import Card from "../assets/icons/card.svg";
import Cart from "../assets/icons/cart.svg";
import Person from "../assets/icons/person.svg";
import Persons from "../assets/icons/persons.svg";
import LeftArrow from "../assets/icons/left-arrow.svg";
import RightArrow from "../assets/icons/right-arrow.svg";
import Radio from "../assets/icons/radio.svg";
import ChartBlue from "../assets/icons/chart-blue.svg";
import ChartGreen from "../assets/icons/chart-green.svg";
import ChartOrange from "../assets/icons/chart-orange.svg";
import More from "../assets/icons/more.svg";
import BtnArrow from "../assets/icons/btn-arrow.svg";
import Delete from "../assets/icons/delete.svg";
import Edit from "../assets/icons/edit.svg";
import Download from "../assets/icons/download.svg";
import Like from "../assets/icons/like.svg";
import Reply from "../assets/icons/reply.svg";
import AllDiscussion from "../assets/icons/all_discussion.svg";
import DiscussionTopic from "../assets/icons/discussion_topic.svg";
import Attendees from "../assets/icons/attendees.svg";
import TotalEvents from "../assets/icons/total_event.svg";
import Tasks from "../assets/icons/tasks.svg";
import Search from "../assets/icons/search.svg";

export default {
  Classroom,
  Community,
  EventManager,
  Logout,
  Manage,
  Overview,
  Setting,
  User,
  Card,
  Cart,
  Person,
  Persons,
  LeftArrow,
  RightArrow,
  Radio,
  ChartBlue,
  ChartGreen,
  ChartOrange,
  More,
  BtnArrow,
  Delete,
  Edit,
  Download,
  Like,
  Reply,
  AllDiscussion,
  DiscussionTopic,
  Attendees,
  TotalEvents,
  Tasks,
  Search,
};
