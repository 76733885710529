export default {
  USER_PROFILE: '/users',
  USER_CHANGE_PASSWORD: '/users/password/change',
  USER_LIST: '/users/index',
  USER_SEARCH: '/users/search',
  USER_DETAIL: '/users/@id',
  USER_INVITE: '/users/invite',
  USER_INVITE_VERIFICATION: '/users/invite-confirmation',
  USER_INVITE_CONFIRMATION: '/users/accept-invitation',
  SUBSCRIPTION_ENGAGEMENT_STATISTICS: '/analytics/subscription-engagements',
  SALE_DETAIL: '/analytics/sales/@id',
  SALES: '/analytics/sales',
  SALES_STATISTICS: '/analytics/sales-statistics',
  COURSES_ANALYTICS: '/analytics/courses',
  COURSE_DETAIL: '/courses/@id',
  COURSE_AUTHORS: '/courses/@courseId/authors',
  COURSE_AUTHOR: '/courses/@courseId/authors/@id',
  COURSE_MODULES: '/courses/@courseId/modules',
  COURSE_MODULE: '/courses/@courseId/modules/@id',
  COURSE_RESOURCES: '/courses/@courseId/resources',
  COURSE_RESOURCE: '/courses/@courseId/resources/@id',
  COURSE_TOPICS: '/courses/@courseId/modules/@moduleId/topic',
  COURSE_TOPIC: '/courses/@courseId/modules/@moduleId/topic/@id',
  COURSE_ANALYTICS_DETAIL: '/analytics/courses/@id',
  SYSTEM_OVERVIEW: '/analytics/overview',
  DOWNLOAD_REPORT: '/report/download',
  TRAINEES: '/analytics/trainees',
  TRAINEE: '/analytics/trainees/@id',
  COURSES: '/courses',
  USER_VIEW: '/users/view/@id',
  DISCUSSIONS: '/discussions',
  DISCUSSION: '/discussions/@id',
  DISCUSSIONS_LIKE: '/discussions/@id/like',
  DISCUSSIONS_COMMENT: '/discussions/@id/comment',
  COURSE_DISCUSSIONS: '/discussions/@courseId',
  DISCUSSION_TOPICS: '/discussions/discussions-topics',
  DISCUSSIONS_TOPICS: '/topics/index',
  EVENTS: '/events',
  EVENT: '/events/@id',
  AUTHORISE_CALENDAR: `${process.env.REACT_APP_API_SERVICE_URL}/api/v1/calendar/get-social-auths`,
  SYNC_EVENT:'/events/@id/sync/google/',
  DELETE_SYNC_EVENT:'/calendar/@id',
  CATEGORIES: '/categories',
  ADD_CATEGORY: '/categories',
  CATEGORY: '/categories/@id',
  CATEGORY_TAGS: '/program-tags',
  // PASSWORD_RESET_REQUEST: '/users/password/order-reset',
  REFRESH_TOKEN: '/users/refresh-token',
  TOPIC_DETAIL: '/topics/view/@id',
  CMS_CASE_STUDY_LIST: '/content-management/case-study',
  CMS_CASE_STUDY_DETAIL: '/content-management/case-study/@id',
  CMS_CASE_STUDY_DETAIL_RESULT: '/content-management/case-study/@id/results',
  CMS_FAQ_LIST: '/content-management/faqs',
  CMS_FAQ_DETAIL: '/content-management/faqs/@id',
  CMS_INSIGHT_LIST: '/posts',
  CMS_INSIGHT_DETAIL: '/posts/@id',
  CMS_SLIDER_LIST: '/content-management/slider',
  CMS_SLIDER_DETAIL: '/content-management/slider/@id',
  CMS_TESTIMONIAL_LIST: '/content-management/testimonial',
  CMS_TESTIMONIAL_DETAIL: '/content-management/testimonial/@id',
};
