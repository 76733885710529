import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import http from "../helpers/http";
import {NETWORK_ERROR_MESSAGE} from "../constants/general";
import { SHOW_SNACKBAR, showSnackbar} from "../store/actions/coreActions";
import {updateQueryParams} from "../helpers/url";
import {isTypeOf} from "../helpers/utility";

const defaultErrorCallback = (error, dispatch, type) => {
  if (!error.response) {
    dispatch({type: SHOW_SNACKBAR, payload: {config: {message: NETWORK_ERROR_MESSAGE}}});
  } else {
    dispatch(showSnackbar({message: `Error getting ${type}: ${error.message}`}));
  }
};

const useHTTPGetRequest = (url, type, queryParams = {}, config = {}) => {
  const mountedRef = useRef(true);
  let {
    errorCallBack = defaultErrorCallback,
    reloadCondition = true,
    errorData,
    // updateCondition = true
  } = config;

  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  if (queryParams) {
    url = updateQueryParams(url, queryParams);
  }
  if (!isTypeOf('Function', errorCallBack)) {
    errorCallBack = defaultErrorCallback;
  }

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if(reloadCondition) {
      setData(null);
      setLoading(true);
      http.get(url).then(
        response => {
          if (!mountedRef.current) return null;
          setData(response.data.data);
          setLoading(false);
        },
        error => {
          if (!mountedRef.current) return null;
          errorCallBack(error, dispatch, type);
          setData((errorData === undefined || errorData === null) ? {} : errorData);
          setLoading(false);
        }
      );
    }
  }, [url, dispatch, type, errorCallBack, reloadCondition, errorData]);
  return [data, loading];
};

export default useHTTPGetRequest;
