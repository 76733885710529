import * as actionTypes from "../actions/cmsActions";

const initialState = {
  testimonialList: null,
  currentTestimonial: null,
  sliderList: null,
  currentSlider: null,
  insightList: null,
  currentInsight: null,
  caseStudyList: null,
  currentCaseStudy: null,
  faqList: null,
  currentFAQ: null,
};

const cmsReducer = (state = initialState, action) => {
  try {
    switch (action.type) {
      case actionTypes.SET_SLIDERS:
        return {
          ...state,
          sliderList: action.payload.data
        };
      case actionTypes.SET_CURRENT_SLIDER:
        return {
          ...state,
          currentSlider: action.payload.data
        };
      case actionTypes.SET_TESTIMONIALS:
        return {
          ...state,
          testimonialList: action.payload.data
        };
      case actionTypes.SET_CURRENT_TESTIMONIAL:
        return {
          ...state,
          currentTestimonial: action.payload.data
        };
      case actionTypes.SET_INSIGHTS:
        return {
          ...state,
          insightList: action.payload.data
        };
      case actionTypes.SET_CURRENT_INSIGHT:
        return {
          ...state,
          currentInsight: action.payload.data
        };
      case actionTypes.SET_CASE_STUDIES:
        return {
          ...state,
          caseStudyList: action.payload.data
        };
      case actionTypes.SET_CURRENT_CASE_STUDY:
        return {
          ...state,
          currentCaseStudy: action.payload.data
        };
      case actionTypes.SET_FAQS:
        return {
          ...state,
          faqList: action.payload.data
        };
      case actionTypes.SET_CURRENT_FAQ:
        return {
          ...state,
          currentFAQ: action.payload.data
        };
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
};

export default cmsReducer;
