import http from "../../helpers/http";
import {replaceDynamics, updateQueryParams} from "../../helpers/url";
import apiRoutes from "../../constants/apiRoutes";
import {errorCallBack} from "../../helpers/utility";
import { showSnackbar } from "./coreActions";
import moment from 'moment';
export const SET_EVENTS = "EVENT/SET_EVENTS";
export const SYNC_EVENTS = "EVENT/SYNC_EVENTS";


export const getEvents = (type, queryParams = {}) => {
  return dispatch => {
    return http.get(updateQueryParams(apiRoutes.EVENTS, queryParams)).then(
      response => {
        dispatch({type: SET_EVENTS, payload: {type, data: response.data.data}});
      },
      errorCallBack(
        dispatch,
        {type: SET_EVENTS, payload: {type, data : {}}},
        `Error getting ${type} events`
      ),
    );
  };
};
export const syncEventWithCalendar = (queryParams = {}) => {
  return dispatch => {
    return  http.get(
      replaceDynamics(apiRoutes.SYNC_EVENT, {id: queryParams.id})
    ).then(response =>{
      if (response && response.data) {
        dispatch(showSnackbar({message: response.data.message}));
        const now = moment().format('YYYY-MM-DDThh:mm');
        dispatch(getEvents('upcoming', {since: now, limit: 4, filter: 'start_date'}));
        dispatch(getEvents('past', {before: now, limit: 4, filter: 'start_date'}));
        return;
      }
    }).catch((e) => {
      dispatch(showSnackbar({message: String(e)}));
    });
  };
};
