import http from "../../helpers/http";
import {replaceDynamics} from "../../helpers/url";
import apiRoutes from "../../constants/apiRoutes";
import {SHOW_SNACKBAR, showSnackbar} from "./coreActions";
import {NETWORK_ERROR_MESSAGE} from "../../constants/general";

export const SET_CURRENT_COURSE = "COURSE/SET_CURRENT_COURSE";
export const SET_CURRENT_EDIT_COURSE = "COURSE/SET_CURRENT_EDIT_COURSE";
export const SET_CATEGORIES = "COURSE/CATEGORIES";
export const GET_CATEGORIES_TAGS = "COURSE/CATEGORIES_TAGS";
export const setCurrentCourse = (course) => {
  return dispatch => {
    dispatch({type: SET_CURRENT_COURSE, payload: {data: course}});
  };
};
export const getCategories = () => {
  return dispatch => {
    return http.get(apiRoutes.CATEGORIES).then(
      response => {
        const {data} = response.data;
        dispatch({type: SET_CATEGORIES, payload: { data }});
      },
      error => {
        if(!error.response) {
          dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: NETWORK_ERROR_MESSAGE} } });
        } else {
          dispatch(showSnackbar({ message: `Error getting course detail: ${error.message}` }));
        }
        const data = [];
        dispatch({type: SET_CATEGORIES, payload: {data}});
      }
    );
  };
};
export const getCategoriesTags = () => {
  return dispatch => {
    return http.get(apiRoutes.CATEGORY_TAGS).then(
      response => {
        const {data} = response.data;
        const courseType = data.filter(p => p.type === 'course_type');
        const deliveryType = data.filter(p => p.type === 'delivery_type');
        dispatch({type: GET_CATEGORIES_TAGS, payload: { courseType, deliveryType }});
      },
      error => {
        if(!error.response) {
          dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: NETWORK_ERROR_MESSAGE} } });
        } else {
          dispatch(showSnackbar({ message: `Error getting course detail: ${error.message}` }));
        }
        dispatch({type: GET_CATEGORIES_TAGS, payload: {data : {}}});
      }
    );
  };
};
export const getCourseDetail = id => {
  return dispatch => {
    return http.get(replaceDynamics(apiRoutes.COURSE_DETAIL, {id})).then(
      response => {
        const {data} = response.data;
        dispatch({type: SET_CURRENT_COURSE, payload: { data }});
      },
      error => {
        if(!error.response) {
          dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: NETWORK_ERROR_MESSAGE} } });
        } else {
          dispatch(showSnackbar({ message: `Error getting course detail: ${error.message}` }));
        }
        dispatch({type: SET_CURRENT_COURSE, payload: {data : {}}});
      }
    );
  };
};
