import http from "../../helpers/http";
import {updateQueryParams} from "../../helpers/url";
import {errorCallBack} from "../../helpers/utility";

export const SET_TESTIMONIALS = "CMS/TESTIMONIALS";
export const SET_CURRENT_TESTIMONIAL = "CMS/CURRENT_TESTIMONIAL";
export const SET_FAQS = "CMS/FAQS";
export const SET_CURRENT_FAQ = "CMS/CURRENT_FAQ";
export const SET_SLIDERS = "CMS/SLIDERS";
export const SET_CURRENT_SLIDER = "CMS/CURRENT_SLIDER";
export const SET_INSIGHTS = "CMS/INSIGHTS";
export const SET_CURRENT_INSIGHT = "CMS/CURRENT_INSIGHT";
export const SET_CASE_STUDIES = "CMS/CASE_STUDIES";
export const SET_CURRENT_CASE_STUDY = "CMS/CURRENT_CASE_STUDY";


export const getCMSData = (url, type, queryParams = {}) => {
  return dispatch => {
    return http.get(updateQueryParams(url, queryParams || {})).then(
      response => {
        const {data} = response.data || {};
        dispatch({type, payload: { data }});
      },
      errorCallBack(
        dispatch,
        {type, payload: {data : {}}},
        `Error getting ${type}`
      ),
    );
  };
};
