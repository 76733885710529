import React, {Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import "./Dashboard.scss";
import Aside from "../../components/Core/Aside/Aside";
import PageLoader from "../PageLoader/PageLoader";
import routes from "../../constants/routes";

const Dashboard = () => {
  return (
    <div className="Dashboard d-flex d-flex col-12 p-0 header-padding">
      <Aside className="aside position-fixed"/>
      <div className="ml-auto pb-5" id="mainContent">
        <Suspense fallback={<PageLoader/>}>
          <Switch>
            {routes.map(({path, exact, component}) => (
              <Route
                key={path}
                exact={exact}
                path={path}
                component={component}
              />
            ))}
            <Redirect from="*" to="/"/>
          </Switch>
        </Suspense>
      </div>
    </div>
  );
};

export default Dashboard;
