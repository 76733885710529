import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import "./AcceptInvite.scss";
import Logo from "../../../assets/icons/logo.png";
import {getFormHandlers} from "../../../store/actions/formsActions";
import url, {updateQueryParams} from "../../../helpers/url";
import useHTTPGetRequest from "../../../hooks/useHTTPGetRequest";
import apiRoutes from "../../../constants/apiRoutes";
import acceptInviteModel from "../../../models/acceptInviteModel";
import Input from "../../../components/UI/Input/Input";
import Skeleton from "react-loading-skeleton";
import validate from "../../../helpers/validator";
import {showSnackbar} from "../../../store/actions/coreActions";
import Loader from "../../../components/Core/Loader/Loader";
import http from "../../../helpers/http";

const AcceptInvite = (props) => {
  const {acceptInviteForm, dispatch, location: {search}} = props;
  const formHandlers = getFormHandlers(dispatch, 'acceptInvite');
  const {
    resetForm, extractFormData, inputChangedHandler, setControlValidations
  } = formHandlers || {};
  let {accessToken} = url.extractQueryParams(search);
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [acceptanceError, setAcceptanceError] = useState();
  const [userData] = useHTTPGetRequest(
    apiRoutes.USER_INVITE_VERIFICATION,
    'confirm user invite',
    {accessToken},
    {errorData: ''}
  );

  useEffect(() => {
    resetForm(acceptInviteModel(userData || ""));
    // eslint-disable-next-line
  }, [userData]);

  const onAcceptInviteClicked = async (ev) => {
    ev.preventDefault();
    if(loading) {
      return;
    }
    setControlValidations();
    let formData;
    const formValidity = validate.form(acceptInviteForm);
    if(!formValidity.isValid) {
      return;
    }
    formData = extractFormData(acceptInviteForm);
    const fDKeys = Object.keys(formData);
    if(formData && !fDKeys.length) {
      dispatch(showSnackbar({ message: `No changes made!` }));
      return;
    }
    if(formData.name) {
      const [first_name, last_name] = formData.name.split(' ').map(n => n.trim());
      formData.first_name = first_name;
      formData.last_name = last_name;
      delete formData.name;
    }
    setLoading(true);
    const response = await http.post(
      updateQueryParams(apiRoutes.USER_INVITE_CONFIRMATION, {accessToken}),
      formData
    ).catch((e) => {
      dispatch(showSnackbar({ message: e.response.data.message || String(e) }));
      if(e && e.response && e.response.data.code === 409 && e.response.data.message) {
        setAcceptanceError(e.response.data.message);
      }
    });
    if(response && response.data.data) {
      setSuccessful(true);
    }
    setLoading(false);
  };

  return (
    <div className="AcceptInvite d-flex align-items-center justify-content-center">
      <div className="d-flex flex-column align-items-center">
        <img src={Logo} alt="" style={{width: "4rem"}}/>
        <div className="accept-wrapper bg-white py-3 px-4 mt-4 rounded">
          <h1 className="font-s-smd font-w-md text-center pt-3">Admin Invitation</h1>
          {
            successful ?
              <div className="d-flex flex-column align-items-center my-5">
                <h2 className="font-s-smd font-w-md text-center pt-3">
                  Your admin account is now ready.
                </h2>
                <p>Kindly click the button below to login to dashboard</p>
                <a
                  href={`${process.env.REACT_APP_BASE_SERVICE_URL}/auth/sign-in`}
                  style={{width: '8rem'}}
                  className="btn btn-secondary font-w-mbd">
                  Login
                </a>
              </div> :
              userData === null ?
                <Skeleton height="8rem" className="d-block w-100"/> :
                userData === '' ?
                  <div className="text-center mt-5">
                    <span className="text-danger font-w-smbd">
                        Invalid invitation link.
                    </span> <br/>
                    The link you provided is either broken or expired.
                    In any case, kindly request a new invitation link.
                  </div> :
                  <form>
                    <p className="my-3 text-center">
                      {
                        acceptanceError ?
                          <span className="text-danger">
                            {acceptanceError}
                          </span> :
                          <span>
                            You have been invited as an administrator on the Bschool platform.
                            Complete your details below to get started.
                          </span>
                      }
                    </p>
                    {
                      acceptInviteForm ?
                        acceptInviteForm.controls.map((cfc, i) => (
                          <Input
                            key={`ev-ctrl-${i}`}
                            {...cfc}
                            onChange={(evt) => inputChangedHandler(cfc, evt)}/>
                        )) : null
                    }
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-secondary font-w-mbd my-3"
                        onClick={onAcceptInviteClicked}>
                      Accept Invite
                      </button>
                      {loading ? <Loader scale={0.5}/> : null}
                    </div>
                  </form>
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    acceptInviteForm: state.formsState.acceptInvite,
  };
};

export default connect(mapStateToProps)(AcceptInvite);
