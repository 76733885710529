import { createMuiTheme } from "@material-ui/core/styles";

export const NETWORK_ERROR_MESSAGE = "Network Error: Please check your internet";

export const DAYS_OF_WEEK =
  ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const MONTHS_OF_YEAR =
  ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const FULL_MONTHS_OF_YEAR =
  [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

export const materialTheme = createMuiTheme({
  typography: {
    fontFamily: ['Inter', 'arial', 'sans-serif'],
  },
  palette: {
    primary: {
      main: '#0E0E0E',
    },
    secondary: {
      main: '#F58634',
    },
  },
});
