import {createStore, combineReducers, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import userReducer from "./reducers/userReducer";
import coreReducer from "./reducers/coreReducer";
import orderReducer from "./reducers/orderReducer";
import cmsReducer from "./reducers/cmsReducer";
import formsReducer from "./reducers/formsReducer";
import courseReducer from "./reducers/courseReducer";
import eventReducer from "./reducers/eventReducer";
import { composeWithDevTools } from 'redux-devtools-extension';

const combinedReducer = combineReducers({
  coreState: coreReducer,
  cmsState: cmsReducer,
  userState: userReducer,
  orderState: orderReducer,
  formsState: formsReducer,
  courseState: courseReducer,
  eventState: eventReducer,
});
const store = createStore(combinedReducer, composeWithDevTools(
  applyMiddleware(thunk)
));
// const store = createStore(combinedReducer, applyMiddleware(thunk));
export default store;
