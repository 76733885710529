import {lazy} from "react";
import Classroom from "../containers/Classroom/Classroom";
import CMS from "../cms/CMS";

const lazyWithPreload = (factory) => {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
};

const Overview = lazyWithPreload(() => import("../containers/Pages/Overview/Overview"));
const Orders = lazyWithPreload(() => import("../containers/Pages/Orders/Orders"));
const Users = lazyWithPreload(() => import("../containers/Pages/Users/Users"));
const OrderDetail = lazyWithPreload(() => import("../containers/Pages/OrderDetail/OrderDetail"));
const Events = lazyWithPreload(() => import("../containers/Pages/Events/Events"));
const EventDetail = lazyWithPreload(() => import("../containers/Pages/EventDetail/EventDetail"));
const EventsWithFilter =
  lazyWithPreload(() => import("../containers/Pages/EventsWithFilter/EventsWithFilter"));
const Community = lazyWithPreload(() => import("../containers/Pages/Community/Community"));
const Settings = lazyWithPreload(() => import("../containers/Pages/Settings/Settings"));

const routes = [
  {path: '/', exact: true, preload: true, component: Overview},
  {path: '/manage-orders/:id', exact: true, preload: true, component: OrderDetail},
  {path: '/manage-orders', exact: true, preload: true, component: Orders},
  {path: '/users', exact: true, preload: true, component: Users},
  {path: '/events', exact: true, preload: true, component: Events},
  {path: '/events/detail/:id', exact: true, preload: true, component: EventDetail},
  {path: '/events/:filter', exact: true, preload: true, component: EventsWithFilter},
  {path: '/community', exact: true, preload: true, component: Community},
  {path: '/settings', exact: true, preload: true, component: Settings},
  {path: '/settings/:action', exact: true, preload: true, component: Settings},
  {path: '/classroom-admin', component: Classroom},
  {path: '/cms', component: CMS},
];


const ClassroomHome =
  lazyWithPreload(() => import("../containers/Pages/ClassroomHome/ClassroomHome"));
const CourseDetail = lazyWithPreload(() => import("../containers/Pages/CourseDetail/CourseDetail"));
const CoursePerformance =
  lazyWithPreload(() => import("../containers/Pages/CoursePerformance/CoursePerformance"));

export const classroomRoutes = [
  {path: '/', exact: true, preload: true, component: ClassroomHome},
  {path: '/:id', exact: true, preload: true, component: CourseDetail},
  {path: '/:id/performance', exact: true, preload: true, component: CoursePerformance},
];


const CMSHome = lazyWithPreload(() => import("../cms/CMSHome/CMSHome"));
const Sliders = lazyWithPreload(() => import("../cms/Sliders/Sliders"));
const Testimonials = lazyWithPreload(() => import("../cms/Testimonials/Testimonials"));
const Insights = lazyWithPreload(() => import("../cms/Insights/Insights"));
const InsightDetail = lazyWithPreload(() => import("../cms/InsightDetail/InsightDetail"));
const CaseStudies = lazyWithPreload(() => import("../cms/CaseStudies/CaseStudies"));
const CaseStudyDetail = lazyWithPreload(() => import("../cms/CaseStudyDetail/CaseStudyDetail"));
const FAQs = lazyWithPreload(() => import("../cms/FAQs/FAQs"));

export const cmsRoutes = [
  {path: '/', exact: true, preload: true, component: CMSHome},
  {path: '/sliders', exact: true, preload: true, component: Sliders},
  {path: '/testimonials', exact: true, preload: true, component: Testimonials},
  {path: '/insights', exact: true, preload: true, component: Insights},
  {path: '/insights/:id', exact: true, preload: true, component: InsightDetail},
  {path: '/case-studies', exact: true, preload: true, component: CaseStudies},
  {path: '/case-studies/:id', exact: true, preload: true, component: CaseStudyDetail},
  {path: '/faqs', exact: true, preload: true, component: FAQs},
];

export default routes;
