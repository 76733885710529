import * as actionTypes from "../actions/formsActions";
import validate, {updateErrorClassName} from "../../helpers/validator";
const initialState = {
  courseOverview: null,
  basicSettings: null,
  passwordSettings: null,
  communityDiscussion: null,
  courseLesson: null,
  eventForm: null,
  programForm: null,
  slider: null,
  testimonial: null,
  faq: null,
  insight: null,
  caseStudy: null,
  impression: null,
  acceptInvite: null,
};

const formsReducer = (state = initialState, action) => {
  try {
    switch (action.type) {
      case actionTypes.SET_FORM_CONTROL_VALUE:
        try {
          const equalValidationResults = validate.equalValidatorAll(
            state[action.payload.formName],
            action.payload.controlName,
            action.payload.value
          );
          return {
            ...state,
            [action.payload.formName]: {
              ...state[action.payload.formName],
              controls: state[action.payload.formName].controls.map(c => {
                let validity = {...c.validity};
                if(c.name === action.payload.controlName) {
                  validity = validate.control(
                    state[action.payload.formName],
                    action.payload.controlName,
                    action.payload.value
                  );
                  c = {
                    ...c,
                    value: action.payload.value,
                    validity,
                    dirty: true,
                    inputProps: {
                      ...c.inputProps,
                      className: updateErrorClassName(c.inputProps.className, validity)
                    }
                  };
                }
                let myErrors = equalValidationResults.filter(e => e.ctrName === c.name);
                if (myErrors.length > 0) {
                  validity = {
                    ...validity,
                    isValid: false
                  };
                  myErrors.filter(e =>
                    !c.validity.errors.find(em => em.message === e.message)
                  ).forEach(e => {
                    validity = {
                      ...validity,
                      errors: validity.errors.concat(e)
                    };
                  });
                } else if (c.name !== action.payload.controlName) {
                  const errors = validity.errors.filter(e => !e.isEqualError);
                  validity = {
                    ...validity,
                    isValid: validity.isValid || !errors.length,
                    errors
                  };
                }
                c = {
                  ...c,
                  validity,
                  inputProps: {
                    ...c.inputProps,
                    className: c.value ?
                      updateErrorClassName(c.inputProps.className, validity) :
                      c.inputProps.className
                  }
                };
                return c;
              }),
            }
          };
        }catch (e) {
          return state;
        }
      case actionTypes.SET_FORM_CONTROL_PROP:
        try {
          return {
            ...state,
            [action.payload.formName]: {
              ...state[action.payload.formName],
              controls: state[action.payload.formName].controls.map(c => {
                if(c.name === action.payload.controlName) {
                  return {
                    ...c,
                    inputProps: {
                      ...c.inputProps,
                      [action.payload.propName]: action.payload.value
                    }
                  };
                }
                return c;
              }),
            }
          };
        }catch (e) {
          return state;
        }
      case actionTypes.SET_FORM_CONTROL_VALIDATIONS:
        try {
          const newForm = {
            ...state[action.payload.formName],
            controls: state[action.payload.formName].controls.map(c => {
              let validity = validate.control(
                state[action.payload.formName],
                c.name,
                c.value
              );
              const newErrors = validity.errors.concat(
                c.validity.errors.filter(er => er.isEqualError)
              );
              validity = {
                ...validity,
                isValid: !newErrors.length,
                errors: newErrors
              };
              return {
                ...c,
                validity,
                inputProps: {
                  ...c.inputProps,
                  className: updateErrorClassName(c.inputProps.className, validity)
                }
              };
            }),
          };
          return {
            ...state,
            [action.payload.formName]: {
              ...newForm,
              validity: validate.form(newForm)
            }
          };
        }catch (e) {
          return state;
        }
      case actionTypes.RESET_FORM_DATA:
        try {
          if(!action.payload.formName || !action.payload.form) {
            return state;
          }
          return {
            ...state,
            [action.payload.formName]: {...action.payload.form}
          };
        } catch (e) {
          return state;
        }
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
};

export default formsReducer;
