import React from "react";
import { Checkbox, FormControlLabel, Radio } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import "./Input.scss";
import FormMessage from "../FormMessage/FormMessage";
import { materialTheme } from "../../../constants/general";
import Switch from "../Switch/Switch";
import RadioGroup from "@material-ui/core/RadioGroup";
import RichTextEditor from "../../Core/RichTextEditor/RichTextEditor";

const Input = (props) => {
  let {
    id,
    isEditMode,
    children,
    parentProps: {
      type,
      label,
      labelClass,
      suffix,
      prefix,
      wrapperClass,
      displayText,
      optionsClassName,
    },
    onChange,
    inputProps,
    name,
    className,
    defaultContent,
    options,
    validity,
    value,
    tooltipPosition,
  } = props || {parentProps: {}, inputProps: {}, validity: {}};

  let inputHTML;
  inputProps.className = [inputProps.className || '', className || ''].join(' ');
  isEditMode = (isEditMode === undefined || isEditMode === null) ? true : isEditMode;
  wrapperClass = [wrapperClass || ''];
  wrapperClass = (inputProps.className || "").includes("form-control") ?
    wrapperClass.concat("form-group") : wrapperClass;
  const errorMessage = ((validity || {}).errors || []).map(e => e.message).join(", ");

  inputProps.className = prefix ? `${inputProps.className} no-border-left` : suffix ?
    `${inputProps.className} no-border-right` : inputProps.className;

  switch (type) {
    case "input":
      inputHTML = (
        <div className="input-group">
          {
            prefix ?
              (<div className="input-group-prepend">{prefix}</div>) : null
          }
          <input {...inputProps} value={value} onChange={onChange}/>
          {
            suffix ?
              (<div className="input-group-append">{suffix}</div>) : null
          }
        </div>
      );
      break;
    case "textarea":
      inputHTML = <textarea {...inputProps} value={value} onChange={onChange}/>;
      break;
    case "select":
      inputHTML = options ? (
        <div className="input-group d-flex align-items-center">
          {
            prefix ?
              (<div className="input-group-prepend">{prefix}</div>) : null
          }
          <select id={id} name={name}
            value={(((options || []).filter(o => o.selected === true)[0]) || {}).value || value}
            onChange={onChange}
            className={inputProps.className}>
            {(options || []).map((o, index) => (
              <option key={index} value={o.value}
                className={[optionsClassName || '', o.className || ''].join(' ')}>
                {o.displayName}
              </option>
            ))}
          </select>
          {
            suffix ?
              (<div className="input-group-append">{suffix}</div>) : null
          }
        </div>
      ) : null;
      break;
    case "checkbox":
      inputHTML = (
        <ThemeProvider theme={materialTheme}>
          <FormControlLabel
            control={
              <Checkbox
                name={name}
                checked={!!value}
                onChange={onChange}
                value={value}
                color="primary"
              />
            }
            label={displayText}
          />
        </ThemeProvider>
      );
      break;
    case "switch":
      inputHTML = (
        <ThemeProvider theme={materialTheme}>
          <FormControlLabel
            control={
              <Switch
                checked={!!value}
                onChange={onChange}
                name={name}
                inputProps={{ 'aria-label': inputProps['aria-label'] }}
              />
            }
            label={displayText}
          />
        </ThemeProvider>
      );
      break;
    case "radio":
      inputHTML = (
        <ThemeProvider theme={materialTheme}>
          <RadioGroup aria-label={name} name={name} value={value} onChange={onChange}>
            {options.map((o, i) => (
              <FormControlLabel
                key={`radio-${i}-${Math.random()}`}
                value={o.value}
                control={<Radio />}
                label={o.label} />
            ))}
          </RadioGroup>
        </ThemeProvider>
      );
      break;
    case "rte":
      inputHTML = (
        <RichTextEditor
          defaultContent={defaultContent}
          onChange={onChange}
          valid={validity.isValid}/>
      );
      break;
    default:
      inputHTML = <input {...inputProps}/>;
      break;
  }

  return isEditMode ? (
    <div className={wrapperClass.join(" ")}>
      {label ? <label htmlFor="name" className={`${labelClass || ''}`}>
        {label}</label> : null}
      <div className="position-relative">
        {errorMessage ?
          <FormMessage
            message={errorMessage}
            position={tooltipPosition}
            id={`${id}_error`}
            className="error"/> :
          null}
        {inputHTML}
      </div>
    </div>
  ) : (
    <div>
      {children || <span>{value}</span>}
    </div>
  );
};

export default Input;
